import { createTheme } from "@mui/material";

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: "0",
                    },
                },
            },
        },
    },
});

export default theme;
