import React, { useState } from "react";

import { VisibilityContext } from "react-horizontal-scrolling-menu";
import heartIcon from "../icon/like.png";
import arrowIconWhite from "../icon/arrow_w.png";
import arrowIconBlack from "../icon/arrow_b.png";

export const Card = ({
    itemId,
    influencerId,
    backgroundUrl,
    selected,
    onClick,
    title,
}: {
    itemId: string;
    influencerId: string;
    backgroundUrl: string;
    selected: boolean;
    onClick: Function;
    title: string;
}) => {
    const visibility = React.useContext(VisibilityContext);
    const isVisible = visibility.useIsVisible(itemId, true);
    const [backgroundColor, setBackgroundColor] = useState("black");
    const [textColor, setTextColor] = useState("white");
    const [arrowIcon, setArrowIcon] = useState(arrowIconWhite);

    const onMouseOver = () => {
        setBackgroundColor("#FFFD63");
        setTextColor("black");
        setArrowIcon(arrowIconBlack);
    };

    const onMouseOut = () => {
        setBackgroundColor("black");
        setTextColor("white");
        setArrowIcon(arrowIconWhite);
    };

    return (
        <div
            onClick={() => onClick()}
            role="button"
            style={{
                // border: "1px solid",
                // display: "inline-block",
                display: "flex",
                flexDirection: "column",
                // padding: "24px",
                width: "320px",
                height: "400px",
                userSelect: "none",
                backgroundColor: backgroundColor,
                marginRight: "12px",
                cursor: "pointer",
                // justifyContent: "center",
                // alignItems: "center",
            }}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            tabIndex={0}
            className="card"
        >
            <div
                style={{
                    display: "flex",
                    padding: "24px",
                }}
            >
                <div
                    style={{
                        fontFamily: "pretendard",
                        color: textColor,
                        width: "",
                        fontSize: "1rem",
                    }}
                >
                    {influencerId.replace(/^.{0,3}/, (match) =>
                        "*".repeat(match.length),
                    )}
                </div>
                <div
                    style={{
                        flexGrow: 1,
                        marginLeft: "9px",
                        // backgroundRepeat: "no-repeat",
                        // backgroundSize: "contain",
                        background: `left / contain no-repeat url("${arrowIcon}")`,
                        width: "16px",
                        height: "16px",
                    }}
                ></div>
                <div
                    style={{
                        background: `url("${heartIcon}")`,
                        width: "21px",
                        height: "18px",
                        backgroundSize: "contain",
                        justifySelf: "end",
                    }}
                ></div>
            </div>

            <div
                style={{
                    margin: "0px 24px 24px 24px",
                    width: "272px",
                    height: "306px",
                    background: `left / contain no-repeat url("${backgroundUrl}")`,
                }}
            ></div>
        </div>
    );
};
