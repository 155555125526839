// import React from "react";
// import logo from "./logo.svg";
import { ThemeProvider } from "@mui/material";
import React from "react";
// import "./App.css";
import { Outlet } from "react-router-dom";
import theme from "./theme/theme";

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <Outlet />
        </ThemeProvider>
    );
};

export default App;
