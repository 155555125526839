import React from "react";
import logoBlack from "./icon/logo_b.png";
import samplePic from "./icon/screenshot.png";
import HorizontalScroller from "./horizontal_scroll/HorizontalScroller";
import { Button } from "@mui/material";
import Flex from "./common/Flex";
import useDeviceSize from "../hook/useDeviceSize";
import influencerPictureList from "./influencerPicureList";

interface WhatWeDoPageProps {
    triggerAnimation: boolean;
}

const WhatWeDoPage: React.FC<WhatWeDoPageProps> = (props): JSX.Element => {
    const [titleClassName, setTitleClassName] = React.useState("ready");
    const [className, setClassName] = React.useState("ready");
    const { triggerAnimation } = props;
    React.useEffect(() => {
        if (triggerAnimation) {
            setTitleClassName("title-fade-in");
            setClassName("fade-in");
        } else {
            setTitleClassName("title-fade-out");
            setClassName("fade-out");
        }
    }, [triggerAnimation]);

    const { isMobileSize } = useDeviceSize();
    return (
        <Flex
            w100
            style={{
                height: isMobileSize ? `auto` : "100vh",
                paddingBottom: isMobileSize ? `30px` : undefined,
                // height: "100vh",
                backgroundColor: "#66DAFF",
            }}
            col
        >
            <Flex>
                {!isMobileSize && (
                    <div
                        style={{
                            position: "absolute",
                            backgroundImage: `url("${logoBlack}")`,
                            minWidth: "115px",
                            minHeight: "42px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            marginTop: "42px",
                            marginLeft: "42px",
                        }}
                    />
                )}
            </Flex>
            <Flex
                gap={54}
                col
                style={{
                    marginTop: isMobileSize ? "74px" : 180,
                    marginLeft: isMobileSize ? "" : "220px",
                }}
            >
                <Flex col flex="1 1 auto" gap={20}>
                    <div
                        style={{
                            fontSize: isMobileSize ? 42 : 48,
                            fontWeight: "bold",
                            // lineHeight: "100px",
                            fontFamily: "pretendard",
                            display: "flex",
                            flexDirection: isMobileSize ? "column" : "row",
                            padding: isMobileSize ? "0 36px" : 0,
                            gap: isMobileSize ? 0 : 20,
                        }}
                        className={titleClassName}
                    >
                        <Flex>{"공동구매"}</Flex>
                        <Flex>{"& 광고 마케팅"}</Flex>
                    </div>
                    {!isMobileSize && (
                        <Flex col>
                            <div
                                style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "300",
                                    lineHeight: "auto",
                                    fontFamily: "pretendard",
                                }}
                            >
                                문엔터에는 100명 이상의 일본 현지 인플루언서가
                                소속되어있습니다.
                            </div>
                            <div
                                style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "300",
                                    lineHeight: "auto",
                                    fontFamily: "pretendard",
                                }}
                            >
                                인플루언서 광고와 공동구매로, 국내 제품을 일본
                                시장에 쉽고 빠르게 판매하실 수 있습니다.
                            </div>
                        </Flex>
                    )}
                </Flex>
                <div
                    style={{
                        // marginTop: "40px",
                        height: "396px",
                        width: "100%",
                        flexGrow: 1,
                    }}
                    className={className}
                >
                    <HorizontalScroller
                        items={influencerPictureList}
                    ></HorizontalScroller>
                </div>
                <Flex alignCenter justifyCenter>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "black",
                            width: 300,
                            height: 50,

                            borderRadius: 100,
                            fontSize: 16,
                            // marginBottom: 100,
                            // marginTop: 40,
                            // marginRight: isMobileSize ? 0 : 200,
                            // marginLeft: 30,
                            // flexGrow: 1,
                            fontFamily: "pretendard",
                        }}
                        onClick={() => {
                            window.open(
                                "https://www.moonamarket.com/",
                                "_blank",
                                // "noopener, noreferrer",
                            );
                        }}
                    >
                        {"공동구매 사이트 바로가기"}
                    </Button>
                </Flex>
                {/* <div
                    role="button"
                    onClick={() => {
                        window.open(
                            "https://www.moonamarket.com/",
                            "_blank",
                            // "noopener, noreferrer",
                        );
                    }}
                    style={{
                        borderRadius: "100px",
                        backgroundColor: "black",
                        width: "300px",
                        height: "50px",
                        marginTop: "55px",
                        marginRight: "60px",
                        
                        marginBottom: "30px",
                        fontSize: "1rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "white",
                        cursor: "pointer",
                    }}
                ></div> */}
            </Flex>
        </Flex>
    );
};

export default React.memo(WhatWeDoPage);
