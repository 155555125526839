import React from "react";
import useScreenSize from "use-screen-size";

const useDeviceSize = () => {
    const size = useScreenSize();
    const result = React.useMemo(
        () => ({
            isWebsize: size.width >= 1280,
            isLandscapeSize: size.width < 1280 && size.width >= 1024,
            isPortraitSize: size.width < 1023 && size.width >= 768,
            isMobileSize: size.width < 768 && size.width >= 0,
        }),
        [size.width],
    );
    return result;
};

export default useDeviceSize;
