import React from "react";
import Flex from "../common/Flex";
import { Button, Checkbox, CircularProgress, TextField } from "@mui/material";
import { ReactComponent as MoonIcon } from "./ic/Moon.svg";
import logoBlack from "../icon/logo_b.png";
import useDeviceSize from "../../hook/useDeviceSize";
import useDebounce from "../../hook/useDebounce";

const ContactSection: React.FC = (): JSX.Element => {
    const [companyName, setCompanyName] = React.useState("");
    const [phoneNumberA, setPhoneNumberA] = React.useState("");
    const [phoneNumberB, setPhoneNumberB] = React.useState("");
    const [phoneNumberC, setPhoneNumberC] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [businessNumberA, setBusinessNumberA] = React.useState("");
    const [businessNumberB, setBusinessNumberB] = React.useState("");
    const [businessNumberC, setBusinessNumberC] = React.useState("");
    const [recommender, setRecommender] = React.useState("");
    const [termsAgree, setTermsAgree] = React.useState(false);

    const { isMobileSize } = useDeviceSize();
    const [loading, setLoading] = React.useState(false);

    const onChangeHandler = React.useCallback(
        (setFunction: React.Dispatch<React.SetStateAction<string>>) =>
            (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setFunction(e.target.value);
            },
        [],
    );

    const onChangeTermsAgree = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setTermsAgree(e.target.checked);
        },
        [],
    );

    const onSubmit = React.useCallback(async () => {
        if (!companyName) {
            alert("업체명을 입력하여 주세요");
        } else if (
            phoneNumberA.length !== 3 ||
            phoneNumberB.length !== 4 ||
            phoneNumberC.length !== 4
        ) {
            alert("핸드폰 번호를 정확히 입력해 주세요");
        } else if (!email) {
            alert("이메일주소를 입력해 주세요");
            // }
            // else if (
            //     businessNumberA.length !== 3 ||
            //     businessNumberB.length !== 2 ||
            //     businessNumberC.length !== 4
            // ) {
            //     alert("사업자 번호를 정확히 입력해 주세요");
        } else if (!termsAgree) {
            alert("개인정보 수집 및 이용에 동의해 주세요");
        } else {
            console.log("on submit!");
            console.log(`업체명: ${companyName}
            연락처: ${phoneNumberA}-${phoneNumberB}-${phoneNumberC}
            이메일: ${email}
            사업자 번호: ${businessNumberA}-${businessNumberB}-${businessNumberC}
            추천인: ${recommender ? recommender : "없음"}
            `);

            const data = {
                formedData: {
                    title: `업체명 ${companyName} 견적 문의, 추천인: ${
                        recommender ? recommender : "없음"
                    }`,
                    // body: {
                    //     companyName,
                    //     phoneNumber: `${phoneNumberA}-${phoneNumberB}-${phoneNumberC}`,
                    //     email,
                    //     businessNumber: `${businessNumberA}-${businessNumberB}-${businessNumberC}`,
                    //     recommender,
                    // },
                    body: `                    업체명: ${companyName} \n
                    연락처: ${phoneNumberA}-${phoneNumberB}-${phoneNumberC} \n
                    이메일: ${email} \n
                    사업자 번호: ${businessNumberA}-${businessNumberB}-${businessNumberC} \n
                    추천인: ${recommender ? recommender : "없음"}`,
                },
            };
            setLoading(true);
            await fetch(
                "https://pw0980hztg.execute-api.ap-northeast-2.amazonaws.com/default/SendMail",
                {
                    method: "POST",
                    headers: {
                        //데이터 타입 지정
                        "Content-Type": "application/json; charset=utf-8",
                    },
                    body: JSON.stringify(data),
                    mode: "no-cors",
                },
            )
                // .then((res) => res.json())
                .then((res) => {
                    setLoading(false);
                    alert("문의가 정상적으로 접수 되었습니다.\n감사합니다.");
                    console.log(res);
                });
        }
    }, [
        businessNumberA,
        businessNumberB,
        businessNumberC,
        companyName,
        email,
        phoneNumberA,
        phoneNumberB,
        phoneNumberC,
        recommender,
        termsAgree,
    ]);

    const debouncedOnSubmit = useDebounce(onSubmit, 100);

    return (
        <Flex w100 style={{ height: "100vh" }} column>
            {loading && (
                <CircularProgress
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        zIndex: 100,
                        // backgroundColor: "rgba(255, 255, 255, 0.5)",
                    }}
                />
            )}
            {!isMobileSize && (
                <div
                    style={{
                        position: "absolute",
                        backgroundImage: `url("${logoBlack}")`,
                        minWidth: "5px",
                        minHeight: "42px",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        marginTop: "42px",
                        marginLeft: "42px",
                        alignSelf: "start",
                    }}
                />
            )}
            <Flex
                style={{
                    margin: isMobileSize ? "74px 0 0" : "88px auto 0",
                    maxWidth: 1480,
                }}
                column
                gap={isMobileSize ? 36 : 60}
                alignCenter
                flex="1 1 auto"
            >
                <Flex
                    gap={isMobileSize ? 24 : 94}
                    style={{
                        flexDirection: isMobileSize ? "column" : "row",
                        padding: isMobileSize ? "0 36px" : "",
                    }}
                >
                    <Flex
                        column
                        gap={20}
                        style={{ width: isMobileSize ? "auto" : 968 }}
                    >
                        {isMobileSize ? (
                            <Flex
                                style={{
                                    fontSize: 42,
                                    fontFamily: "pretendard",
                                    fontWeight: "600",
                                }}
                            >
                                {"CONTACT"}
                            </Flex>
                        ) : (
                            <Flex
                                style={{
                                    fontSize: 20,
                                    fontFamily: "pretendard",
                                    fontWeight: "500",
                                }}
                            >
                                {"기본 정보 입력"}
                            </Flex>
                        )}
                        <Flex column gap={10} w100>
                            <Flex
                                alignCenter
                                gap={4}
                                style={{
                                    fontFamily: "pretendard",
                                    fontSize: 18,
                                }}
                            >
                                {"업체명"}
                                <Flex style={{ color: "red" }}>{"*"}</Flex>
                            </Flex>
                            <TextField
                                style={{ height: 50 }}
                                inputProps={{
                                    style: {
                                        padding: "12px 16px",
                                        backgroundColor: "#F1F1F1",
                                        fontFamily: "pretendard",
                                        fontSize: 16,
                                    },
                                }}
                                onChange={onChangeHandler(setCompanyName)}
                                value={companyName}
                                InputProps={{
                                    style: { borderRadius: 0, border: "" },
                                }}
                                required
                                placeholder="업체명을 입력해 주세요"
                            />
                        </Flex>
                        <Flex column gap={10}>
                            <Flex
                                alignCenter
                                gap={4}
                                style={{
                                    fontFamily: "pretendard",
                                    fontSize: 18,
                                }}
                            >
                                {"연락처"}
                                <Flex style={{ color: "red" }}>{"*"}</Flex>
                            </Flex>
                            <Flex alignCenter gap={8}>
                                <TextField
                                    style={{ height: 50, width: 80 }}
                                    inputMode="numeric"
                                    inputProps={{
                                        style: {
                                            padding: "12px 16px",
                                            backgroundColor: "#F1F1F1",
                                            fontFamily: "pretendard",
                                            fontSize: 16,
                                        },
                                    }}
                                    InputProps={{
                                        style: { borderRadius: 0, border: "" },
                                    }}
                                    onChange={onChangeHandler(setPhoneNumberA)}
                                    value={phoneNumberA}
                                    required
                                    placeholder="010"
                                />
                                {"-"}
                                <TextField
                                    style={{ height: 50, width: 98 }}
                                    inputProps={{
                                        style: {
                                            padding: "12px 16px",
                                            backgroundColor: "#F1F1F1",
                                            fontFamily: "pretendard",
                                            fontSize: 16,
                                        },
                                    }}
                                    InputProps={{
                                        style: { borderRadius: 0, border: "" },
                                    }}
                                    required
                                    placeholder="0000"
                                    onChange={onChangeHandler(setPhoneNumberB)}
                                    value={phoneNumberB}
                                />
                                {"-"}
                                <TextField
                                    style={{ height: 50, width: 98 }}
                                    inputProps={{
                                        style: {
                                            padding: "12px 16px",
                                            backgroundColor: "#F1F1F1",
                                            fontFamily: "pretendard",
                                            fontSize: 16,
                                        },
                                    }}
                                    InputProps={{
                                        style: { borderRadius: 0, border: "" },
                                    }}
                                    required
                                    placeholder="0000"
                                    onChange={onChangeHandler(setPhoneNumberC)}
                                    value={phoneNumberC}
                                />
                            </Flex>
                        </Flex>
                        <Flex column gap={10}>
                            <Flex
                                alignCenter
                                gap={4}
                                style={{
                                    fontFamily: "pretendard",
                                    fontSize: 18,
                                }}
                            >
                                {"이메일"}
                                <Flex style={{ color: "red" }}>{"*"}</Flex>
                            </Flex>
                            <TextField
                                inputMode="email"
                                style={{ height: 50 }}
                                inputProps={{
                                    style: {
                                        padding: "12px 16px",
                                        backgroundColor: "#F1F1F1",
                                        fontFamily: "pretendard",
                                    },
                                }}
                                InputProps={{
                                    style: { borderRadius: 0, border: "" },
                                }}
                                required
                                placeholder="이메일을 입력해 주세요"
                                onChange={onChangeHandler(setEmail)}
                                value={email}
                            />
                        </Flex>
                        <Flex column gap={10}>
                            <Flex
                                alignCenter
                                gap={4}
                                style={{
                                    fontFamily: "pretendard",
                                    fontSize: 18,
                                }}
                            >
                                {"사업자등록번호"}
                                <Flex style={{ color: "red" }}>{"*"}</Flex>
                            </Flex>
                            <Flex alignCenter gap={8}>
                                <TextField
                                    style={{ height: 50, width: 80 }}
                                    inputMode={"numeric"}
                                    inputProps={{
                                        style: {
                                            padding: "12px 16px",
                                            backgroundColor: "#F1F1F1",
                                            fontFamily: "pretendard",
                                            fontSize: 16,
                                        },
                                    }}
                                    InputProps={{
                                        style: { borderRadius: 0, border: "" },
                                    }}
                                    required
                                    placeholder="000"
                                    onChange={onChangeHandler(
                                        setBusinessNumberA,
                                    )}
                                    value={businessNumberA}
                                />
                                {"-"}
                                <TextField
                                    style={{ height: 50, width: 74 }}
                                    inputProps={{
                                        style: {
                                            padding: "12px 16px",
                                            backgroundColor: "#F1F1F1",
                                            fontFamily: "pretendard",
                                            fontSize: 16,
                                        },
                                    }}
                                    InputProps={{
                                        style: { borderRadius: 0, border: "" },
                                    }}
                                    required
                                    placeholder="00"
                                    onChange={onChangeHandler(
                                        setBusinessNumberB,
                                    )}
                                    value={businessNumberB}
                                />
                                {"-"}
                                <TextField
                                    style={{ height: 50, width: 104 }}
                                    inputProps={{
                                        style: {
                                            padding: "12px 16px",
                                            backgroundColor: "#F1F1F1",
                                            fontFamily: "pretendard",
                                            fontSize: 16,
                                        },
                                    }}
                                    InputProps={{
                                        style: { borderRadius: 0, border: "" },
                                    }}
                                    required
                                    placeholder="0000"
                                    onChange={onChangeHandler(
                                        setBusinessNumberC,
                                    )}
                                    value={businessNumberC}
                                />
                            </Flex>
                        </Flex>
                        <Flex
                            column
                            gap={10}
                            style={{ fontFamily: "pretendard", fontSize: 18 }}
                        >
                            {"추천인(선택사항)"}
                            <TextField
                                style={{ height: 50 }}
                                inputProps={{
                                    style: {
                                        padding: "12px 16px",
                                        backgroundColor: "#F1F1F1",
                                        fontFamily: "pretendard",
                                        fontSize: 16,
                                    },
                                }}
                                InputProps={{
                                    style: { borderRadius: 0, border: "" },
                                }}
                                placeholder="추천인을 입력해 주세요"
                                onChange={onChangeHandler(setRecommender)}
                                value={recommender}
                            />
                        </Flex>
                    </Flex>
                    <Flex column gap={20}>
                        {!isMobileSize && (
                            <Flex
                                style={{
                                    fontFamily: "pretendard",
                                    fontSize: 20,
                                    fontWeight: "500",
                                }}
                            >
                                {"개인정보 수집 및 이용 동의"}
                            </Flex>
                        )}
                        <Flex gap={10} column>
                            <Flex
                                alignCenter
                                gap={4}
                                style={{
                                    fontFamily: "pretendard",
                                    fontSize: 18,
                                }}
                            >
                                {isMobileSize
                                    ? "개인정보 수집 및 이용 동의"
                                    : "필수동의"}
                                <Flex style={{ color: "red" }}>{"*"}</Flex>
                            </Flex>
                            <Flex
                                style={{
                                    padding: "40px 34px",
                                    maxWidth: 418,
                                    backgroundColor: "#F1F1F1",
                                }}
                            >
                                <Flex column gap={16} style={{ fontSize: 14 }}>
                                    <Flex
                                        style={{
                                            marginBottom: "12px",
                                            fontFamily: "pretendard",
                                        }}
                                    >
                                        {
                                            "문엔터테인먼트(이하 ‘회사’라 한다)는 개인정보 보호법 제30조에 따라 정보 주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리지침을 수립, 공개합니다."
                                        }
                                    </Flex>
                                    <Flex column>
                                        <Flex
                                            style={{ fontFamily: "pretendard" }}
                                        >
                                            {"1.   수집하는 개인정보의 항목"}
                                        </Flex>
                                        <Flex
                                            style={{ fontFamily: "pretendard" }}
                                        >
                                            {
                                                "-   회사는 원활한 고객상담, 각종 서비스 등 기본적인 서비스 제공을 위한 필수정보와 고객 맟춤 서비스 제공을 위한 선택정보로 구분하여 아래와 같은 개인정보를 수집하고 있습니다."
                                            }
                                        </Flex>
                                        <Flex
                                            style={{ fontFamily: "pretendard" }}
                                        >
                                            {
                                                "-   필수정보: 담당자명, 연락처, 이메일, 업체명, 사업자등록번호"
                                            }
                                        </Flex>
                                        <Flex
                                            style={{ fontFamily: "pretendard" }}
                                        >
                                            {"-   선택정보: 추천인"}
                                        </Flex>
                                    </Flex>
                                    <Flex column>
                                        <Flex
                                            style={{ fontFamily: "pretendard" }}
                                        >
                                            {"2.   개인정보 수집 목적"}
                                        </Flex>
                                        <Flex
                                            style={{ fontFamily: "pretendard" }}
                                        >
                                            {
                                                "-   광고주관련 정보는 온라인 광고 대행 및 상담 처리, 유지 관리 서비스와 홈페이지 및 모바일 사이트 제작, 기존 홈페이지 유지보수 및 개발 관련 등의 상담처리를 위한 연락 과정에서 사용됩니다."
                                            }
                                        </Flex>
                                    </Flex>
                                    <Flex column>
                                        <Flex
                                            style={{ fontFamily: "pretendard" }}
                                        >
                                            {"3.   개인정보의 보유 및 이용기간"}
                                        </Flex>
                                        <Flex
                                            style={{ fontFamily: "pretendard" }}
                                        >
                                            {
                                                "-   수집된 개인정보는 수집 목적이 종료되면 재생할 수 없는 방법으로 지체없이 파기합니다."
                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex
                            alignCenter
                            gap={4}
                            style={{ fontFamily: "pretendard", fontSize: 16 }}
                        >
                            <Checkbox
                                size="medium"
                                style={{ padding: 0, width: 22, height: 22 }}
                                sx={{
                                    color: "black",
                                    "&.Mui-checked": {
                                        color: "black",
                                    },
                                }}
                                checked={termsAgree}
                                onChange={onChangeTermsAgree}
                                // icon={<Unchecked />}
                                // checkedIcon={<CheckboxIcon />}
                            />
                            {"개인정보 수집 및 이용에 동의합니다."}
                        </Flex>
                    </Flex>
                </Flex>
                <Button
                    onClick={debouncedOnSubmit}
                    variant="contained"
                    style={{
                        backgroundColor: "black",
                        width: 288,
                        // height: 50,
                        borderRadius: 100,
                        fontSize: 18,
                        marginBottom: isMobileSize ? 36 : 60,
                        fontFamily: "pretendard",
                    }}
                >
                    {"문의하기"}
                </Button>
            </Flex>
            <Flex
                style={{
                    padding: "26px 46px",
                    backgroundColor: "#FFFD63",
                    fontSize: "14px",
                    // minHeight: "134px",
                    fontFamily: "pretendard",
                }}
                col
                gap={isMobileSize ? 16 : 20}
                spaceBetween
            >
                <Flex col gap={4}>
                    <Flex alignCenter gap={8}>
                        <Flex>
                            <MoonIcon />
                        </Flex>
                        <Flex
                            style={{
                                fontSize: "18px",
                                fontWeight: "bold",
                                fontFamily: "pretendard",
                            }}
                        >
                            {"MoonEnt."}
                        </Flex>
                    </Flex>
                    <Flex
                        style={{
                            justifyContent: isMobileSize ? "" : "space-between",
                            flexDirection: isMobileSize ? "column" : "row",
                            gap: isMobileSize ? 16 : 0,
                        }}
                    >
                        {isMobileSize ? (
                            <Flex
                                col
                                style={{
                                    fontSize: 9,
                                    fontFamily: "pretendard",
                                }}
                            >
                                <Flex>{"대표 : 문성배"}</Flex>
                                <Flex>{"Tel : 010-5435-9083"}</Flex>
                                <Flex>
                                    {"Email : help@moonentertainment.co.kr"}
                                </Flex>
                                <Flex>{"사업자번호 : 752-06-03212"}</Flex>
                                <Flex>
                                    {
                                        "주소 : Japan osaka city higashi shinsaibashi1-3-22(〒 542-0083)"
                                    }
                                </Flex>
                            </Flex>
                        ) : (
                            <Flex
                                style={{
                                    padding: "0 0 0 26px",
                                    fontFamily: "pretendard",
                                }}
                            >
                                {
                                    "대표 : 문성배   ｜  Tel : 010-5435-9083   ｜  Email : help@moonentertainment.co.kr   ｜  사업자번호 : 752-06-03212   ｜  주소 : Japan osaka city higashi shinsaibashi1-3-22(〒 542-0083)"
                                }
                            </Flex>
                        )}
                        <Flex
                            style={{
                                justifyContent: isMobileSize ? "" : "flex-end",
                                fontFamily: "pretendard",
                                fontSize: isMobileSize ? 9 : 14,
                            }}
                        >
                            <Flex>{"이용약관     ｜"}</Flex>
                            <Flex style={{ fontWeight: "bold" }}>
                                {"    개인정보취급방침     "}
                            </Flex>
                            <Flex>{"｜    무나마켓"}</Flex>
                        </Flex>
                    </Flex>
                </Flex>
                <Flex
                    style={{
                        justifyContent: isMobileSize ? "" : "center",
                        fontFamily: "pretendard",
                        fontSize: isMobileSize ? 8 : 14,
                    }}
                >
                    {"Copyright ⓒ MoonEnt. Co., Ltd. All Rights Reserved."}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ContactSection;
