import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import LandingPage from "../component/LandingPage";
import AdminPage from "../component/admin/AdminPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        // Component: App,
        errorElement: <></>,
        children: [
            { path: "", element: <LandingPage /> },
            { path: "/admin", element: <AdminPage /> },
        ],
    },
]);

export default router;
