import { useRef, useState } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { DragDealer } from "./DragDealer";
import { LeftArrow, RightArrow } from "./arrows";
import { Card } from "./Card";
import "react-horizontal-scrolling-menu/dist/styles.css";
import "./hide-scrollbar.css";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
const HorizontalScroller: React.FC<{
    items: {
        id: number;
        influencerId: string;
        url: string;
        followers?: string;
    }[];
}> = (props) => {
    const onWheel = (
        apiObj: scrollVisibilityApiType,
        ev: React.WheelEvent,
    ): void => {
        const isThouchpad =
            Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

        if (isThouchpad) {
            ev.stopPropagation();
            return;
        }

        if (ev.deltaY < 0) {
            apiObj.scrollNext();
        } else if (ev.deltaY > 0) {
            apiObj.scrollPrev();
        }
    };

    const [items] = useState(props.items);

    // NOTE: for drag by mouse
    const dragState = useRef(new DragDealer());

    const handleDrag =
        ({ scrollContainer }: scrollVisibilityApiType) =>
        (ev: React.MouseEvent) =>
            dragState.current.dragMove(ev, (posDiff: number) => {
                if (scrollContainer.current) {
                    scrollContainer.current.scrollLeft += posDiff;
                }
            });

    const [selected, setSelected] = useState<string>("");
    const handleItemClick = (influencerId: string) => () => {
        if (dragState.current.dragging) {
            return false;
        }
        // setSelected(selected !== itemId ? itemId : "");
        // if (influencerId) {
        //     window.open(`https://www.instagram.com/${influencerId}`, "_blank");
        // }
    };

    return (
        <>
            <div style={{ width: "100%" }}>
                <div onMouseLeave={dragState.current.dragStop}>
                    <ScrollMenu
                        // LeftArrow={LeftArrow}
                        // RightArrow={RightArrow}
                        onWheel={onWheel}
                        onMouseDown={() => dragState.current.dragStart}
                        onMouseUp={() => dragState.current.dragStop}
                        onMouseMove={handleDrag}
                    >
                        {items.map(({ id, influencerId, url }) => (
                            <Card
                                title={influencerId}
                                influencerId={influencerId}
                                backgroundUrl={url}
                                itemId={influencerId} // NOTE: itemId is required for track items
                                key={id}
                                onClick={handleItemClick(influencerId)}
                                selected={influencerId === selected}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>
        </>
    );
};

export default HorizontalScroller;
