import React from "react";
import mainBackground from "./icon/01_main_bg.png";
import "jquery.ripples";
const $ = require("jquery");

interface MainPageProps {
    triggerAnimation: boolean;
}

const MainPage: React.FC<MainPageProps> = (props) => {
    React.useEffect(() => {
        console.log("ripples");
        $("#backgroundImage").ripples({
            perturbance: 0.01,
            dropRadius: 12,
            resolution: 1024,
        });
    }, []);

    const [titleClassName, setTitleClassName] = React.useState("ready");
    const [className, setClassName] = React.useState("ready");
    const { triggerAnimation } = props;
    React.useEffect(() => {
        if (triggerAnimation) {
            setTitleClassName("title-fade-in");
            setClassName("fade-in");
        } else {
            setTitleClassName("title-fade-out");
            setClassName("fade-out");
        }
    }, [triggerAnimation]);
    return (
        <div
            id={"backgroundImage"}
            style={{
                display: "flex",
                width: "100vw",
                height: "100vh",
                backgroundImage: `url("${mainBackground}")`,
                alignItems: "center",
                justifyContent: "center",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundColor: "rgb(5, 10, 16)",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "auto",
                    height: "auto",
                }}
            >
                <div
                    style={{
                        color: "#FFFD63",
                        fontSize: `clamp(0.5rem, 1vw, 1rem)`,
                        width: "100%",
                        textAlign: "center",
                        letterSpacing: `clamp(0.5px, 1vw, 10px)`,
                    }}
                    className={`${className} one`}
                >
                    GLOBAL ENTERTAINMENT
                </div>
                <div
                    style={{
                        textAlign: "center",

                        color: "#FFFD63",
                        fontSize: `clamp(3.5rem, 10vw, 7.5rem)`,
                        width: "100%",
                        fontFamily: "montaga",
                    }}
                    className={`${titleClassName} two`}
                >
                    MOON
                </div>
            </div>
        </div>
    );
};

export default MainPage;
