import React from "react";
import Flex from "../common/Flex";
import { Avatar, Button } from "@mui/material";
import { ReactComponent as FollowersIcon } from "./ic/Followers.svg";
import { ReactComponent as RightArrowWhite } from "./ic/RightArrowWhite.svg";
import logoBlack from "../icon/logo_b.png";
import influencerInfoList from "./ic/profile/influencerInfoList";
import useDeviceSize from "../../hook/useDeviceSize";

interface InfluencerListSectionProps {
    triggerAnimation: boolean;
}

const InfluencerListSection: React.FC<InfluencerListSectionProps> = (
    props,
): JSX.Element => {
    const [titleClassName, setTitleClassName] = React.useState("ready");
    const [className, setClassName] = React.useState("ready");
    const { triggerAnimation } = props;
    React.useEffect(() => {
        if (triggerAnimation) {
            setTitleClassName("title-fade-in");
            setClassName("fade-in");
        } else {
            setTitleClassName("title-fade-out");
            setClassName("fade-out");
        }
    }, [triggerAnimation]);
    const { isMobileSize } = useDeviceSize();
    const initailNumberOfInf = React.useMemo(() => {
        return isMobileSize ? 15 : 24;
    }, [isMobileSize]);
    const numberOfInfPerPage = React.useMemo(() => {
        return isMobileSize ? 9 : 24;
    }, [isMobileSize]);
    const infList = React.useMemo(() => {
        return influencerInfoList;
    }, []);

    const [infListPage, setInfListPage] = React.useState(1);

    const [renderInfList, setRenderInfList] = React.useState(
        Object.entries(infList)
            .slice(0, initailNumberOfInf)
            .map(([, val]) => val),
    );

    React.useEffect(() => {
        if (isMobileSize) {
            setRenderInfList(
                Object.entries(infList)
                    .slice(0, 15)
                    .map(([, val]) => val),
            );
        } else {
            setRenderInfList(
                Object.entries(infList)
                    .slice(0, 24)
                    .map(([, val]) => val),
            );
        }
    }, [infList, isMobileSize]);

    const [hoveredId, setHoveredId] = React.useState<string | null>(null);
    const onMouseEnter = React.useCallback((hoveredId: string) => {
        return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            setHoveredId(hoveredId);
        };
    }, []);
    const onMouseLeave = React.useCallback(
        (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            e.preventDefault();
            e.stopPropagation();
            setHoveredId(null);
        },
        [],
    );

    const [isLastPage, setIsLastPage] = React.useState(() =>
        Object.keys(infList).length <= initailNumberOfInf ? true : false,
    );

    const onClickShowMore = React.useCallback(() => {
        if (
            Object.keys(infList).length <=
            initailNumberOfInf + numberOfInfPerPage * infListPage
        ) {
            setRenderInfList(Object.entries(infList).map(([, val]) => val));
            setIsLastPage(true);
        } else {
            setRenderInfList(
                Object.entries(infList)
                    .slice(
                        0,
                        initailNumberOfInf + numberOfInfPerPage * infListPage,
                    )
                    .map(([, val]) => val),
            );
        }
        setInfListPage((prev) => ++prev);
    }, [infList, infListPage, initailNumberOfInf, numberOfInfPerPage]);

    const onClickAvatar = React.useCallback((instagram: string) => {
        return () =>
            window.open(
                "https://www.instagram.com/" + instagram,
                "_blank",
                "noopener, noreferrer",
            );
    }, []);
    return (
        <Flex
            style={{ minHeight: "100vh", backgroundColor: "#FFFD63" }}
            gap={42}
            col
            alignCenter
            w100
        >
            {!isMobileSize && (
                <div
                    style={{
                        position: "absolute",
                        backgroundImage: `url("${logoBlack}")`,
                        minWidth: "115px",
                        minHeight: "42px",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        marginTop: "42px",
                        marginLeft: "42px",
                        alignSelf: "start",
                    }}
                />
            )}
            <Flex
                style={{
                    margin: "88px auto 0",
                    maxWidth: "1480px",
                }}
                col
                gap={28}
            >
                <Flex
                    style={{
                        fontSize: isMobileSize ? "42px" : "48px",
                        fontWeight: isMobileSize ? "600" : "bold",
                        fontFamily: "pretendard",
                        padding: isMobileSize ? "0 36px" : "",
                    }}
                    className={titleClassName}
                >
                    {"문엔터와 함께하는 인플루언서"}
                </Flex>
                <Flex
                    gap={isMobileSize ? 10 : 40}
                    alignCenter
                    style={{
                        flexWrap: "wrap",
                        justifyContent: isMobileSize
                            ? "space-evenly"
                            : "flex-start",
                    }}
                    className={className}
                >
                    {renderInfList.map((inf) => {
                        return (
                            <Flex
                                col
                                alignCenter
                                gap={isMobileSize ? 6 : 12}
                                key={inf.id}
                            >
                                <Avatar
                                    // sx={{
                                    //     ":hover": {
                                    //         "::before": {
                                    //             content: "''",
                                    //             position: "absolute",
                                    //             width: "100%",
                                    //             height: "100%",
                                    //             backgroundColor: "rgba(0,0,0,0.7)",
                                    //         },
                                    //     },
                                    // }}
                                    src={inf.photo}
                                    style={{
                                        width: isMobileSize ? 100 : 150,
                                        height: isMobileSize ? 100 : 150,
                                    }}
                                    onMouseEnter={onMouseEnter(inf.id)}
                                />
                                <div
                                    style={{
                                        width: isMobileSize ? 100 : 150,
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        textAlign: "center",
                                        fontFamily: "pretendard",
                                    }}
                                >
                                    {inf.id.replace(/^.{0,3}/, (match) =>
                                        "*".repeat(match.length),
                                    )}
                                </div>
                                {hoveredId === inf.id && (
                                    <Flex
                                        style={{
                                            color: "white",
                                            width: isMobileSize ? 100 : 150,
                                            height: isMobileSize ? 100 : 150,
                                            backgroundColor: "rgba(0,0,0,0.7)",
                                            position: "absolute",
                                            cursor: "pointer",
                                            borderRadius: isMobileSize
                                                ? 100
                                                : 150,
                                        }}
                                        alignCenter
                                        justifyCenter
                                        // onClick={onClickAvatar(inf.id)}
                                        onMouseLeave={onMouseLeave}
                                    >
                                        {/* {isMobileSize ? (
                                            <Flex
                                                style={{
                                                    fontFamily: "pretendard",
                                                    fontSize: 14,
                                                }}
                                                alignCenter
                                            >
                                                {"바로가기"}
                                                <RightArrowWhite />
                                            </Flex>
                                        ) : (
                                            <Flex
                                                style={{
                                                    fontFamily: "pretendard",
                                                    fontSize: 18,
                                                }}
                                            >
                                                <FollowersIcon />
                                                {inf.followers}
                                            </Flex>
                                        )} */}
                                        <Flex
                                            style={{
                                                fontFamily: "pretendard",
                                                fontSize: 18,
                                            }}
                                        >
                                            <FollowersIcon />
                                            {inf.followers}
                                        </Flex>
                                    </Flex>
                                )}
                            </Flex>
                        );
                    })}
                </Flex>
            </Flex>
            {isLastPage ? (
                <Flex h={54}></Flex>
            ) : (
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: "black",
                        width: 288,
                        // height: 50,
                        borderRadius: 100,
                        fontSize: 18,
                        fontFamily: "pretendard",
                        // marginBottom: 54,
                    }}
                    onClick={onClickShowMore}
                >
                    {"인플루언서 더 보기"}
                </Button>
            )}
            <Flex />
        </Flex>
    );
};

export default React.memo(InfluencerListSection);
