import yuuka_stagram from "./influencerPic/1.png";
import m_atsurino from "./influencerPic/2.png";
import asami_chomi_official from "./influencerPic/3.png";
import tomo0510nagi from "./influencerPic/4.png";
import tajiri_natsuki from "./influencerPic/5.png";
import kanagram_08 from "./influencerPic/6.png";
import reichan_na from "./influencerPic/7.png";
import hit0mi_n_ from "./influencerPic/8.png";
import miku_enamiku from "./influencerPic/9.png";
import __mibuu from "./influencerPic/10.png";
import yumeno_rinka from "./influencerPic/11.png";
import kesera_qung from "./influencerPic/12.png";
import asuka__0928 from "./influencerPic/13.png";
import miii_b1n from "./influencerPic/14.png";
import amanecco_721 from "./influencerPic/15.png";
import meimei_michiko_mei from "./influencerPic/16.png";
import michan_095 from "./influencerPic/17.png";
import djryota_doggindahouse from "./influencerPic/18.png";
import _7x16__ from "./influencerPic/19.png";
import yukinan0930 from "./influencerPic/20.png";
import pagos_rurika from "./influencerPic/21.png";
import toshizou0628 from "./influencerPic/22.png";
import _maozheng_ from "./influencerPic/23.png";
import _1225mello from "./influencerPic/24.png";
import akari_sr8 from "./influencerPic/25.png";
import alessa_amusic from "./influencerPic/26.png";
import takahiro_nanase from "./influencerPic/27.png";
// import akiko_u1102 from "./influencerPic/28.png";
import yukalily713 from "./influencerPic/29.png";
import xx_yu__xx from "./influencerPic/30.png";
import dragonryu7_ from "./influencerPic/31.png";
import klutch_3_etking from "./influencerPic/32.png";
import wakaba_feerie from "./influencerPic/33.png";
import non_choco from "./influencerPic/34.png";
import rikopingram_t from "./influencerPic/35.png";
import chepi_mr from "./influencerPic/36.png";
import _15umll from "./influencerPic/37.png";
import chie__stella from "./influencerPic/38.png";
import msk_miel from "./influencerPic/39.png";
import keisuke_z_official from "./influencerPic/40.png";
import massamaan from "./influencerPic/41.png";
import fuchan_728 from "./influencerPic/42.png";
import chanmami_golf from "./influencerPic/43.png";
import kksry_1231 from "./influencerPic/44.png";
import ganada49_358 from "./influencerPic/45.png";
import noaig___ from "./influencerPic/46.png";
import moeko_0309 from "./influencerPic/47.png";
import _10sherry02 from "./influencerPic/48.png";
import kinm_103 from "./influencerPic/49.png";
import rensta_official from "./influencerPic/50.png";
import arisa_minato from "./influencerPic/51.png";
import na7na1 from "./influencerPic/52.png";
import sak__37 from "./influencerPic/53.png";
import minorin_04m from "./influencerPic/54.png";
import r_en009 from "./influencerPic/55.png";
import nene12_4 from "./influencerPic/56.png";
// import itoiyoshio_7 from "./influencerPic/57.png";
// import yano_akihiro3988 from "./influencerPic/58.png";
// import sekimoto_kentaro from "./influencerPic/59.png";
// import nohmi_atsushi_official from "./influencerPic/60.png";
// import keisuke_kanoh_official from "./influencerPic/61.png";
// import ryotaimanari from "./influencerPic/62.png";
// import fujikawa_kyuji22 from "./influencerPic/63.png";

const influencerPictureList = [
    { id: 1, influencerId: "yuuka_stagram", url: yuuka_stagram },
    { id: 2, influencerId: "m_atsurino", url: m_atsurino },
    { id: 3, influencerId: "asami_chomi_official", url: asami_chomi_official },
    { id: 4, influencerId: "tomo0510nagi", url: tomo0510nagi },
    { id: 5, influencerId: "tajiri_natsuki", url: tajiri_natsuki },
    { id: 6, influencerId: "kanagram_08", url: kanagram_08 },
    { id: 7, influencerId: "reichan.na", url: reichan_na },
    { id: 8, influencerId: "hit0mi_n_", url: hit0mi_n_ },
    { id: 9, influencerId: "miku_enamiku", url: miku_enamiku },
    { id: 10, influencerId: "__mibuu", url: __mibuu },
    { id: 11, influencerId: "yumeno_rinka", url: yumeno_rinka },
    { id: 12, influencerId: "kesera_qung", url: kesera_qung },
    { id: 13, influencerId: "asuka__0928", url: asuka__0928 },
    { id: 14, influencerId: "miii_b1n", url: miii_b1n },
    { id: 15, influencerId: "amanecco.721", url: amanecco_721 },
    { id: 16, influencerId: "meimei_michiko.mei", url: meimei_michiko_mei },
    { id: 17, influencerId: "michan_095", url: michan_095 },
    {
        id: 18,
        influencerId: "djryota_doggindahouse",
        url: djryota_doggindahouse,
    },
    { id: 19, influencerId: "7x16__", url: _7x16__ },
    { id: 20, influencerId: "yukinan0930", url: yukinan0930 },
    { id: 21, influencerId: "pagos_rurika", url: pagos_rurika },
    { id: 22, influencerId: "toshizou0628", url: toshizou0628 },
    { id: 23, influencerId: "_maozheng_", url: _maozheng_ },
    { id: 24, influencerId: "1225mello", url: _1225mello },
    { id: 25, influencerId: "akari.sr8", url: akari_sr8 },
    { id: 26, influencerId: "alessa.amusic", url: alessa_amusic },
    { id: 27, influencerId: "takahiro.nanase", url: takahiro_nanase },
    // { id: 28, influencerId: "akiko.u1102", url: akiko_u1102 },
    { id: 29, influencerId: "yukalily713", url: yukalily713 },
    { id: 30, influencerId: "xx.yu__.xx", url: xx_yu__xx },
    { id: 31, influencerId: "dragonryu7_", url: dragonryu7_ },
    { id: 32, influencerId: "klutch_3_etking", url: klutch_3_etking },
    { id: 33, influencerId: "wakaba_feerie", url: wakaba_feerie },
    { id: 34, influencerId: "non.choco", url: non_choco },
    { id: 35, influencerId: "rikopingram_t", url: rikopingram_t },
    { id: 36, influencerId: "chepi_mr", url: chepi_mr },
    { id: 37, influencerId: "15umll", url: _15umll },
    { id: 38, influencerId: "chie__stella", url: chie__stella },
    { id: 39, influencerId: "msk_miel", url: msk_miel },
    { id: 40, influencerId: "keisuke_z_official", url: keisuke_z_official },
    { id: 41, influencerId: "massamaan", url: massamaan },
    { id: 42, influencerId: "fuchan.728", url: fuchan_728 },
    { id: 43, influencerId: "chanmami.golf", url: chanmami_golf },
    { id: 44, influencerId: "kksry.1231", url: kksry_1231 },
    { id: 45, influencerId: "ganada49.358", url: ganada49_358 },
    { id: 46, influencerId: "noaig___", url: noaig___ },
    { id: 47, influencerId: "moeko_0309", url: moeko_0309 },
    { id: 48, influencerId: "10sherry02", url: _10sherry02 },
    { id: 49, influencerId: "kinm_103", url: kinm_103 },
    { id: 50, influencerId: "rensta_official", url: rensta_official },
    { id: 51, influencerId: "arisa_minato", url: arisa_minato },
    { id: 52, influencerId: "na7na1", url: na7na1 },
    { id: 53, influencerId: "sak__37", url: sak__37 },
    { id: 54, influencerId: "minorin_04m", url: minorin_04m },
    { id: 55, influencerId: "r_en009", url: r_en009 },
    { id: 56, influencerId: "nene12.4", url: nene12_4 },
    // { id: 57, influencerId: "itoiyoshio_7", url: itoiyoshio_7 },
    // { id: 58, influencerId: "yano_akihiro3988", url: yano_akihiro3988 },
    // { id: 59, influencerId: "sekimoto_kentaro", url: sekimoto_kentaro },
    // {
    //     id: 60,
    //     influencerId: "nohmi_atsushi_official",
    //     url: nohmi_atsushi_official,
    // },
    // {
    //     id: 61,
    //     influencerId: "keisuke_kanoh_official",
    //     url: keisuke_kanoh_official,
    // },
    // { id: 62, influencerId: "ryotaimanari", url: ryotaimanari },
    // { id: 63, influencerId: "fujikawa_kyuji22", url: fujikawa_kyuji22 },
];

export default influencerPictureList;
