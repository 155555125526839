import React from "react";
import CheckListPage from "./CheckListPage";
import WhatWeDoPage from "./WhatWeDoPage";
import MainPage from "./MainPage";
import ContactSection from "./contact/ContactSection";
import InfluencerListSection from "./influencer/InfluencerListSection";
import "@shinyongjun/react-fullpage/css";
import "./fadein.css";

// import { FullPageScroll } from "./fullpagescroll/FullPageScroll";
import {
    FullpageContainer,
    FullpageSection,
} from "@shinyongjun/react-fullpage";

const LandingPage: React.FC = () => {
    const [activeIndex, setActiveIndex] = React.useState<number>(0);
    return (
        // <div style={{ overflow: "hidden" }}>
        // <FullPageScroll>
        //     <MainPage />
        //     <CheckListPage />
        //     <WhatWeDoPage />
        //     <InfluencerListSection />
        //     <ContactSection />
        // </FullPageScroll>
        <FullpageContainer
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
        >
            <FullpageSection>
                <MainPage triggerAnimation={activeIndex === 0} />
            </FullpageSection>
            <FullpageSection>
                <CheckListPage triggerAnimation={activeIndex === 1} />
            </FullpageSection>
            <FullpageSection>
                <WhatWeDoPage triggerAnimation={activeIndex === 2} />
            </FullpageSection>
            <FullpageSection>
                <InfluencerListSection triggerAnimation={activeIndex === 3} />
            </FullpageSection>
            <FullpageSection>
                <ContactSection />
            </FullpageSection>
        </FullpageContainer>
        // </div>
    );
};

export default LandingPage;
