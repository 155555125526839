import React from "react";

interface FlexProps
    extends React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLDivElement>,
        HTMLDivElement
    > {
    w100?: boolean;
    w?: number;
    h100?: boolean;
    h?: number;
    gap?: number;
    column?: boolean;
    col?: boolean;
    row?: boolean;
    flex?: string;
    spaceBetween?: boolean;
    alignCenter?: boolean;
    justifyCenter?: boolean;
}

const Flex = (props: FlexProps) => {
    const {
        style,
        w100,
        w,
        h100,
        h,
        gap,
        column,
        col,
        row,
        flex,
        spaceBetween,
        alignCenter,
        justifyCenter,
        ...rest
    } = props;
    return (
        <div
            style={{
                display: "flex",
                width: w ? w : w100 ? "100%" : "auto",
                height: h ? h : h100 ? "100%" : "auto",
                gap: gap || 0,
                flexDirection: column || col ? "column" : row ? "row" : "unset",
                justifyContent: spaceBetween
                    ? "space-between"
                    : justifyCenter
                    ? "center"
                    : "unset",
                alignItems: alignCenter ? "center" : "unset",
                flex: flex ? flex : "",
                ...style,
            }}
            {...rest}
        ></div>
    );
};

export default React.memo(Flex);
