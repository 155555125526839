import React from "react";
import logoWhite from "./icon/logo_w.png";
import useDeviceSize from "../hook/useDeviceSize";
import { ReactComponent as YelloArrow } from "./icon/arrow_y.svg";
import Flex from "./common/Flex";

interface ChecklListPageProps {
    triggerAnimation: boolean;
}

const CheckListPage: React.FC<ChecklListPageProps> = (props): JSX.Element => {
    const [titleClassName, setTitleClassName] = React.useState("ready");
    const [className, setClassName] = React.useState("ready");
    const { triggerAnimation } = props;
    React.useEffect(() => {
        if (triggerAnimation) {
            setTitleClassName("title-fade-in");
            setClassName("fade-in");
        } else {
            setTitleClassName("title-fade-out");
            setClassName("fade-out");
        }
    }, [triggerAnimation]);

    const { isPortraitSize, isMobileSize } = useDeviceSize();
    return (
        <div
            style={{
                display: "flex",
                width: "100vw",
                height:
                    isMobileSize || isPortraitSize
                        ? `clamp(1050px, 1vh, 1.5vh)`
                        : "100vh",
                backgroundColor: "black",
                overflow: "hidden",
            }}
        >
            {isPortraitSize || isMobileSize ? null : (
                <div
                    style={{
                        backgroundImage: `url("${logoWhite}")`,
                        minWidth: "115px",
                        minHeight: "42px",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        marginTop: "42px",
                        marginLeft: "42px",
                    }}
                ></div>
            )}

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: isPortraitSize || isMobileSize ? "0px" : "190px",
                    marginLeft: isPortraitSize || isMobileSize ? "0px" : "60px",
                    width: isPortraitSize || isMobileSize ? "100%" : undefined,
                    padding:
                        isMobileSize || isPortraitSize
                            ? `50px 30px 30px 30px`
                            : "0px",
                    height: "100vh",
                    minWidth:
                        isMobileSize || isPortraitSize ? undefined : "520px",
                }}
            >
                <div>
                    <div
                        style={{
                            color: "#FFFD63",
                            fontSize: `clamp(2.625rem, 5vw, 5rem)`,
                            fontFamily: "pretendard",
                            fontWeight: 600,
                        }}
                        className={titleClassName}
                    >
                        일본 진출,
                    </div>
                    <div
                        style={{
                            color: "white",
                            fontSize: `clamp(2.625rem, 5vw, 5rem)`,
                            fontFamily: "pretendard",
                            fontWeight: 400,
                        }}
                        className={titleClassName}
                    >
                        어떻게 해야할지
                    </div>
                    <div
                        style={{
                            color: "white",
                            fontSize: `clamp(2.625rem, 5vw, 5rem)`,
                            fontFamily: "pretendard",
                            fontWeight: 400,
                        }}
                        className={titleClassName}
                    >
                        모르겠어요.
                    </div>
                </div>
                {isPortraitSize || isMobileSize ? (
                    <div
                        style={{
                            width: "100%",
                            // marginRight: "220px",
                            marginTop: "50px",
                            flexGrow:
                                isMobileSize || isPortraitSize ? undefined : 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: "#FFFD63",
                                borderRadius: "50px",
                                width: `220px`,
                                height: "auto",
                                fontSize: "0.875rem",
                                lineHeight: "18px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "pretendard",
                                textAlign: "center",
                                padding: "16px 21px",
                                marginRight: "39px",
                                transition: "",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            className={`${className} one`}
                        >
                            <Flex>{"일본 진출을 하고 싶은데"}</Flex>
                            <Flex>{"무엇부터 시작해야 될지 모르겠다."}</Flex>
                        </div>
                        <div
                            style={{
                                border: "1px solid",
                                borderColor: "#FFFD63",
                                color: "#FFFD63",
                                borderRadius: "50px",
                                width: `220px`,
                                height: "auto",
                                marginTop: "18px",
                                fontSize: "0.875rem",
                                lineHeight: "18px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "pretendard",
                                textAlign: "center",
                                padding: "16px 21px",
                                marginLeft: "39px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            className={`${className} two`}
                        >
                            <Flex>{"일본 인플루언서를 활용하여"}</Flex>
                            <Flex>{"브랜드 인지도를 높이고 싶다."}</Flex>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#FFFD63",
                                borderRadius: "50px",
                                width: `220px`,
                                height: "auto",
                                marginTop: "18px",
                                fontSize: "0.875rem",
                                lineHeight: "18px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "pretendard",
                                textAlign: "center",
                                padding: "16px 21px",
                                marginRight: "39px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            className={`${className} three`}
                        >
                            <Flex>{"일본 트렌드 및"}</Flex>
                            <Flex>{"유효한 마케팅 전략을 알 수 없다."}</Flex>
                        </div>
                        <div
                            style={{
                                border: "1px solid",
                                color: "#FFFD63",
                                borderColor: "#FFFD63",
                                borderRadius: "50px",
                                width: `220px`,
                                height: "auto",
                                marginTop: "18px",
                                fontSize: "0.875rem",
                                lineHeight: "18px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "pretendard",
                                textAlign: "center",
                                padding: "16px 21px",
                                marginLeft: "39px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            className={`${className} four`}
                        >
                            <Flex>{"일본어 번역 및 일본 고객 지원(CS)에"}</Flex>
                            <Flex>{"대한 어려움이 있다."}</Flex>
                        </div>
                        <div
                            style={{
                                border: "1px solid",
                                borderColor: "#FFFD63",
                                color: "#FFFD63",
                                borderRadius: "50px",
                                width: `220px`,
                                height: "auto",
                                marginTop: "18px",
                                fontSize: "0.875rem",
                                lineHeight: "18px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "pretendard",
                                textAlign: "center",
                                padding: "16px 21px",
                                marginRight: "39px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            className={`${className} five`}
                        >
                            <Flex>{"일본 진출 및 마케팅에 대한 편리하고"}</Flex>
                            <Flex>{"빠른 서포트를 받고 싶다."}</Flex>
                        </div>
                        <div
                            style={{
                                backgroundColor: "#FFFD63",
                                borderRadius: "50px",
                                width: `220px`,
                                height: "auto",
                                marginTop: "18px",
                                fontSize: "0.875rem",
                                lineHeight: "18px",
                                justifyContent: "center",
                                alignItems: "center",
                                fontFamily: "pretendard",
                                textAlign: "center",
                                padding: "16px 21px",
                                marginLeft: "39px",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            className={`${className} six`}
                        >
                            <Flex>{"일본에 법인을 설립하지 않고"}</Flex>
                            <Flex>{"일본 시장에 진입하고 싶다."}</Flex>
                        </div>
                        <YelloArrow
                            style={{ margin: `22px 0px`, alignSelf: "center" }}
                        />
                    </div>
                ) : null}
                <div
                    style={{
                        color: "white",
                        paddingTop:
                            isMobileSize || isPortraitSize
                                ? undefined
                                : "1.375rem",
                        lineHeight:
                            isMobileSize || isPortraitSize ? "24px" : "30px",
                        fontSize:
                            isMobileSize || isPortraitSize
                                ? "0.938rem"
                                : "1.375rem",
                        fontFamily: "pretendard",
                    }}
                    className={titleClassName}
                >
                    <div style={{ fontFamily: "pretendard" }}>
                        침체되어가는 국내 경기에,
                    </div>
                    <div style={{ fontFamily: "pretendard" }}>
                        세계 3위 경제 대국인 일본 시장 진출은 필수입니다.
                    </div>
                    <div style={{ fontFamily: "pretendard" }}>
                        {isMobileSize || isPortraitSize ? (
                            <br></br>
                        ) : (
                            "정보 부족으로 해외 진출을 망설이고 있는 자영업자분들께,"
                        )}
                    </div>
                    <div style={{ fontFamily: "pretendard" }}>
                        {isMobileSize || isPortraitSize
                            ? "정보 부족으로 해외 진출을 망설이고 있는 자영업자분들께, 국내와 일본시장을 잇는 파트너가 되어드리겠습니다."
                            : "국내와 일본시장을 잇는 파트너가 되어드리겠습니다."}
                    </div>
                </div>
            </div>
            {!(isPortraitSize || isMobileSize) ? (
                <div
                    style={{
                        width: "100%",
                        // marginRight: "220px",
                        paddingRight: "9%",
                        flexGrow: 1,
                        marginTop: "210px",
                        display: "flex",
                        alignItems: "end",
                        flexDirection: "column",
                    }}
                >
                    <div
                        style={{
                            borderRadius: "100px",
                            backgroundColor: "#FFFD63",
                            width: `clamp(200px, 30vw, 490px)`,
                            minHeight: "50px",
                            fontSize: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "pretendard",
                            transition: "",
                        }}
                        className={`${className} one`}
                    >
                        일본 진출을 하고 싶은데 무엇부터 시작해야 될지 모르겠다.
                    </div>
                    <div
                        style={{
                            border: "1px solid",
                            borderRadius: "100px",
                            borderColor: "#FFFD63",
                            color: "#FFFD63",
                            width: `clamp(200px, 30vw, 490px)`,
                            minHeight: "50px",
                            marginTop: "40px",
                            fontSize: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "pretendard",
                        }}
                        className={`${className} two`}
                    >
                        일본 인플루언서를 활용하여 브랜드 인지도를 높이고 싶다.
                    </div>
                    <div
                        style={{
                            borderRadius: "100px",
                            backgroundColor: "#FFFD63",
                            width: `clamp(200px, 30vw, 490px)`,
                            minHeight: "50px",
                            marginTop: "40px",
                            marginRight: "72px",
                            fontSize: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "pretendard",
                        }}
                        className={`${className} three`}
                    >
                        일본 트렌드 및 유효한 마케팅 전략을 알 수 없다.
                    </div>
                    <div
                        style={{
                            border: "1px solid",
                            borderRadius: "100px",
                            color: "#FFFD63",
                            borderColor: "#FFFD63",
                            width: `clamp(200px, 30vw, 490px)`,
                            minHeight: "50px",
                            marginTop: "40px",
                            fontSize: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "pretendard",
                        }}
                        className={`${className} four`}
                    >
                        일본어 번역 및 일본 고객 지원(CS)에 대한 어려움이 있다.
                    </div>
                    <div
                        style={{
                            border: "1px solid",
                            borderRadius: "100px",
                            borderColor: "#FFFD63",
                            color: "#FFFD63",
                            width: `clamp(200px, 30vw, 490px)`,
                            minHeight: "50px",
                            marginTop: "40px",
                            marginRight: "125px",
                            fontSize: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "pretendard",
                        }}
                        className={`${className} five`}
                    >
                        일본 진출 및 마케팅에 대한 편리하고 빠른 서포트를 받고
                        싶다.
                    </div>
                    <div
                        style={{
                            borderRadius: "100px",
                            backgroundColor: "#FFFD63",
                            width: `clamp(200px, 30vw, 490px)`,
                            minHeight: "50px",
                            marginTop: "40px",
                            marginRight: "194px",
                            fontSize: "1rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontFamily: "pretendard",
                        }}
                        className={`${className} six`}
                    >
                        일본에 법인을 설립하지 않고 일본 시장에 진입하고 싶다.
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default React.memo(CheckListPage);
