import yuuka_stagram from "./1.png";
import m_atsurino from "./2.png";
import asami_chomi_official from "./3.png";
import tomo0510nagi from "./4.png";
import tajiri_natsuki from "./5.png";
import kanagram_08 from "./6.png";
import reichan_na from "./7.png";
import hit0mi_n_ from "./8.png";
import miku_enamiku from "./9.png";
import __mibuu from "./10.png";
import yumeno_rinka from "./11.png";
import kesera_qung from "./12.png";
import asuka__0928 from "./13.png";
import miii_b1n from "./14.png";
import amanecco_721 from "./15.png";
import meimei_michiko_mei from "./16.png";
import michan_095 from "./17.png";
import djryota_doggindahouse from "./18.png";
import _7x16__ from "./19.png";
import yukinan0930 from "./20.png";
import pagos_rurika from "./21.png";
import toshizou0628 from "./22.png";
import _maozheng_ from "./23.png";
import _1225mello from "./24.png";
import akari_sr8 from "./25.png";
import alessa_amusic from "./26.png";
import takahiro_nanase from "./27.png";
// import akiko_u1102 from "./28.png";
import yukalily713 from "./29.png";
import xx_yu__xx from "./30.png";
import dragonryu7_ from "./31.png";
import klutch_3_etking from "./32.png";
import wakaba_feerie from "./33.png";
import non_choco from "./34.png";
import rikopingram_t from "./35.png";
import chepi_mr from "./36.png";
import _15umll from "./37.png";
import chie__stella from "./38.png";
import msk_miel from "./39.png";
import keisuke_z_official from "./40.png";
import massamaan from "./41.png";
import fuchan_728 from "./42.png";
import chanmami_golf from "./43.png";
import kksry_1231 from "./44.png";
import ganada49_358 from "./45.png";
import noaig___ from "./46.png";
import moeko_0309 from "./47.png";
import _10sherry02 from "./48.png";
import kinm_103 from "./49.png";
import rensta_official from "./50.png";
import arisa_minato from "./51.png";
import na7na1 from "./52.png";
import sak__37 from "./53.png";
import minorin_04m from "./54.png";
import r_en009 from "./55.png";
import nene12_4 from "./56.png";
// import itoiyoshio_7 from "./57.png";
// import yano_akihiro3988 from "./58.png";
// import sekimoto_kentaro from "./59.png";
// import nohmi_atsushi_official from "./60.png";
// import keisuke_kanoh_official from "./61.png";
// import ryotaimanari from "./62.png";
// import fujikawa_kyuji22 from "./63.png";

const influencerInfoList = {
    yuuka_stagram: {
        id: "yuuka_stagram",
        photo: yuuka_stagram,
        followers: "137k",
    },
    m_atsurino: { id: "m_atsurino", photo: m_atsurino, followers: "105k" },
    asami_chomi_official: {
        id: "asami_chomi_official",
        photo: asami_chomi_official,
        followers: "92k",
    },
    tomo0510nagi: { id: "tomo0510nagi", photo: tomo0510nagi, followers: "79k" },
    tajiri_natsuki: {
        id: "tajiri_natsuki",
        photo: tajiri_natsuki,
        followers: "72k",
    },
    kanagram_08: { id: "kanagram_08", photo: kanagram_08, followers: "65k" },
    reichan_na: { id: "reichan.na", photo: reichan_na, followers: "62k" },
    hit0mi_n_: { id: "hit0mi_n_", photo: hit0mi_n_, followers: "54k" },
    miku_enamiku: { id: "miku_enamiku", photo: miku_enamiku, followers: "51k" },
    __mibuu: { id: "__mibuu", photo: __mibuu, followers: "52k" },
    yumeno_rinka: { id: "yumeno_rinka", photo: yumeno_rinka, followers: "39k" },
    kesera_qung: { id: "kesera_qung", photo: kesera_qung, followers: "41k" },
    asuka__0928: { id: "asuka__0928", photo: asuka__0928, followers: "51k" },
    miii_b1n: { id: "miii_b1n", photo: miii_b1n, followers: "40k" },
    amanecco_721: { id: "amanecco.721", photo: amanecco_721, followers: "35k" },
    meimei_michiko_mei: {
        id: "meimei_michiko.mei",
        photo: meimei_michiko_mei,
        followers: "34k",
    },
    michan_095: { id: "michan_095", photo: michan_095, followers: "34k" },
    djryota_doggindahouse: {
        id: "djryota_doggindahouse",
        photo: djryota_doggindahouse,
        followers: "26k",
    },
    _7x16__: { id: "7x16__", photo: _7x16__, followers: "26k" },
    yukinan0930: { id: "yukinan0930", photo: yukinan0930, followers: "28k" },
    pagos_rurika: { id: "pagos_rurika", photo: pagos_rurika, followers: "21k" },
    toshizou0628: { id: "toshizou0628", photo: toshizou0628, followers: "22k" },
    _maozheng_: { id: "_maozheng_", photo: _maozheng_, followers: "21k" },
    _1225mello: { id: "1225mello", photo: _1225mello, followers: "20k" },
    akari_sr8: { id: "akari.sr8", photo: akari_sr8, followers: "20k" },
    alessa_amusic: {
        id: "alessa.amusic",
        photo: alessa_amusic,
        followers: "17k",
    },
    takahiro_nanase: {
        id: "takahiro.nanase",
        photo: takahiro_nanase,
        followers: "17k",
    },
    // akiko_u1102: { id: "akiko.u1102", photo: akiko_u1102, followers: "17k" },
    yukalily713: { id: "yukalily713", photo: yukalily713, followers: "16k" },
    xx_yu__xx: { id: "xx.yu__.xx", photo: xx_yu__xx, followers: "16k" },
    dragonryu7_: { id: "dragonryu7_", photo: dragonryu7_, followers: "17k" },
    klutch_3_etking: {
        id: "klutch_3_etking",
        photo: klutch_3_etking,
        followers: "16k",
    },
    wakaba_feerie: {
        id: "wakaba.feerie",
        photo: wakaba_feerie,
        followers: "19k",
    },
    non_choco: { id: "non.choco", photo: non_choco, followers: "17k" },
    rikopingram_t: {
        id: "rikopingram_t",
        photo: rikopingram_t,
        followers: "14k",
    },
    chepi_mr: { id: "chepi_mr", photo: chepi_mr, followers: "14k" },
    _15umll: { id: "15umll", photo: _15umll, followers: "12k" },
    chie__stella: { id: "chie__stella", photo: chie__stella, followers: "11k" },
    msk_miel: { id: "msk_miel", photo: msk_miel, followers: "11k" },
    keisuke_z_official: {
        id: "keisuke_z_official",
        photo: keisuke_z_official,
        followers: "10k",
    },
    massamaan: { id: "massamaan", photo: massamaan, followers: "11k" },
    fuchan_728: { id: "fuchan.728", photo: fuchan_728, followers: "11k" },
    chanmami_golf: {
        id: "chanmami.golf",
        photo: chanmami_golf,
        followers: "10k",
    },
    kksry_1231: { id: "kksry.1231", photo: kksry_1231, followers: "11k" },
    ganada49_358: { id: "ganada49.358", photo: ganada49_358, followers: "10k" },
    noaig___: { id: "noaig___", photo: noaig___, followers: "10k" },
    moeko_0309: { id: "moeko_0309", photo: moeko_0309, followers: "9k" },
    _10sherry02: { id: "10sherry02", photo: _10sherry02, followers: "8k" },
    kinm_103: { id: "kinm_103", photo: kinm_103, followers: "8k" },
    rensta_official: {
        id: "rensta_official",
        photo: rensta_official,
        followers: "8k",
    },
    arisa_minato: { id: "arisa_minato", photo: arisa_minato, followers: "7k" },
    na7na1: { id: "na7na1", photo: na7na1, followers: "96k" },
    sak__37: { id: "sak__37", photo: sak__37, followers: "48k" },
    minorin_04m: { id: "minorin_04m", photo: minorin_04m, followers: "34k" },
    r_en009: { id: "r_en009", photo: r_en009, followers: "30k" },
    nene12_4: { id: "nene12.4", photo: nene12_4, followers: "30k" },
    // itoiyoshio_7: {
    //     id: "itoiyoshio_7",
    //     photo: itoiyoshio_7,
    //     followers: "337k",
    // },
    // yano_akihiro3988: {
    //     id: "yano_akihiro3988",
    //     photo: yano_akihiro3988,
    //     followers: "90k",
    // },
    // sekimoto_kentaro: {
    //     id: "sekimoto_kentaro",
    //     photo: sekimoto_kentaro,
    //     followers: "41k",
    // },
    // nohmi_atsushi_official: {
    //     id: "nohmi_atsushi_official",
    //     photo: nohmi_atsushi_official,
    //     followers: "90k",
    // },
    // keisuke_kanoh_official: {
    //     id: "keisuke_kanoh_official",
    //     photo: keisuke_kanoh_official,
    //     followers: "29k",
    // },
    // ryotaimanari: { id: "ryotaimanari", photo: ryotaimanari, followers: "37k" },
    // fujikawa_kyuji22: {
    //     id: "fujikawa_kyuji22",
    //     photo: fujikawa_kyuji22,
    //     followers: "209k",
    // },
};

export default influencerInfoList;
